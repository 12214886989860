<template>
  <div class="mt-4  px-5">


    <v-data-table
        :headers="headers"
        :items="comp_Ranges"
        sort-by="name"
        class="elevation-1"
        ref="Ranges"
    >
      <template v-slot:top>
        <v-toolbar
            flat
            color="success"
        ><h3>Stände</h3>
          <v-spacer></v-spacer>
          <v-dialog
              v-model="dialog"
              max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon class="mr-2">mdi-map-marker</v-icon>
                Stand anlegen
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                <span class="text-h5">Stand anlegen</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="close">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form ref="form" v-on:submit.prevent>
                    <v-row>
                      <v-col cols="3">
                        <v-subheader class="mySubheader">(*)Kurz - Bezeichnung</v-subheader>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                            v-model="newShootingRange.KurzBez"
                            :rules="TextRules"
                            :error-messages="errorMessages"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3">
                        <v-subheader class="mySubheader">(*)Lang - Bezeichnung</v-subheader>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                            v-model="newShootingRange.LangBez"
                            :rules="TextRules"
                            :error-messages="errorMessages"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3">
                        <v-subheader class="mySubheader">(*)Kapazität - Plätze</v-subheader>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                            v-model="newShootingRange.Places"
                            :rules="TextRules"
                            :error-messages="errorMessages"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="close">
                  Abbrechen
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="setRange">
                  Speichern
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn icon
                 @click="getRanges">
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:item.action="{ item }">
        <v-btn
            class="info my-1 mx-1"
            text @click="openEditDialog(item)">
          <v-icon>
            mdi-pencil
          </v-icon>
        </v-btn>
        <v-btn
            class="btn-error"
            color="error"
            @click="delRange(item)">
          <v-icon>
            mdi-delete
          </v-icon>
        </v-btn>

      </template>

      <template v-slot:no-data>
        <v-btn
            color="primary"
            @click="initialize"
        >
          Reset
        </v-btn>
      </template>
    </v-data-table>


    <v-dialog
        v-model="dialog_update"
        max-width="600px">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          <span class="text-h5">Stand ändern</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeEditDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form ref="form_update" v-on:submit.prevent>
            <v-container>
              <v-row>
                <v-col cols="3">
                  <v-subheader class="mySubheader">(*)Kurz - Bezeichnung</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                      v-model="EditRange.KurzBez"
                      :rules="TextRules"
                      :error-messages="errorMessages"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-subheader class="mySubheader">(*)Lang - Bezeichnung</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                      v-model="EditRange.LangBez"
                      :rules="TextRules"
                      :error-messages="errorMessages"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-subheader class="mySubheader">(*)Kapazität - Plätze</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                      v-model="EditRange.totalplaces"
                      :rules="TextRules"
                      :error-messages="errorMessages"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="closeEditDialog"
          >
            Abbrechen
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="updateRange">
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'ranges',
  data: () => ({
    dialog: false,
    dialog_update: false,
    dialogDelete: false,
    loading: false,
    errorMessages: '',
    headers: [
      {
        align: 'Name',
        sortable: false,
      },
      {text: 'Kurz', value: 'KurzBez'},
      {text: 'Name', value: 'LangBez'},
      {text: 'Bahnen', value: 'totalplaces'},
      {text: 'Einträge Schiessbuch', value: 'AnzahlSB'},
      {text: 'ID', value: 'id'},
      {text: 'Action', value: 'action', sortable: false, align: 'center'}
    ],
    newShootingRange: {
      KurzBez: null,
      LangBez: null,
      Places: null,
    },
    EditRange: {},

    TextRules: [
      v => !!v || 'Eingabe erforderlich',
    ],
  }),

  computed: {
    comp_Ranges() {
      return this.$store.state.ClubApp.ShootingRanges;
    },

  },

  mounted() {
    this.getRanges();
  },

  methods: {

    async getRanges() {
      await this.$store.dispatch('ClubApp/getRanges')
    },

    async setRange() {

      if (this.newShootingRange.KurzBez === '' || this.newShootingRange.KurzBez === null ||
          this.newShootingRange.LangBez === '' || this.newShootingRange.LangBez === null ||
          this.newShootingRange.Places === '' || this.newShootingRange.Places === null) {
        this.errorMessages = 'Pflichtfelder (*) müssen ausgefüllt werden!'
        return
      }

      if (this.$refs.form.validate()) {
        var Data = {
          StandKurzbez: this.newShootingRange.KurzBez,
          StandLangbez: this.newShootingRange.LangBez,
          totalplaces: this.newShootingRange.Places
        }
        await this.$store.dispatch('ClubApp/setShootingRange', Data)
            .then(this.dialog = false);


        this.$refs.form.resetValidation();
        this.$refs.form.reset();
        this.errorMessages = '';

        this.newShootingRange = {
          KurzBez: null,
          LangBez: null,
          Places: null
        };


      }
    },

    openEditDialog(item) {
      this.EditRange = {};
      this.EditRange = item;
      this.dialog_update = true;

    },
    async updateRange() {

      if (this.EditRange.KurzBez === '' || this.EditRange.KurzBez === null ||
          this.EditRange.LangBez === '' || this.EditRange.LangBez === null ||
          this.EditRange.totalplaces === '' || this.EditRange.totalplaces === null) {
        this.errorMessages = 'Pflichtfelder (*) müssen ausgefüllt werden!'
        return
      }

      if (this.$refs.form_update.validate()) {
        var Data = {
          StandID: this.EditRange.id,
          StandKurzbez: this.EditRange.KurzBez,
          StandLangbez: this.EditRange.LangBez,
          totalplaces: this.EditRange.totalplaces
        }
        await this.$store.dispatch('ClubApp/updateShootingRange', Data)
            .then(this.dialog_update = false);

        this.$refs.form_update.resetValidation();
        this.$refs.form_update.reset();
        this.errorMessages = '';
        this.EditRange = {};
      }
    },

    closeEditDialog() {
      this.dialog_update = false;
      this.errorMessages = '';
      this.EditRange = {};
    },


    async delRange(item) {

      var Data = {
        inputDelStand: item.id
      }
      await this.$store.dispatch('ClubApp/delShootingRange', Data)

    },

    initialize() {
      this.getRanges();
    },
    close() {
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
        this.$refs.form.reset();
      }
      this.errorMessages = '';
      this.dialog = false;


    },

  },
}
</script>

<style scoped>

.mySubheader {
  font-weight: bold;
}

#Hinweis_span {
  font-weight: bold;
}
</style>

